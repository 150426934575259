import axios from "axios";
let endpoint =
  process.env.REACT_APP_API_ENDPOINT || "http://109.226.16.253:3001/api";
class Services {
  userLogin(username, password) {
    return axios
      .post(endpoint + "/login", {
        username,
        password,
      })
      .then((result) => {
        document.cookie = `token=${result.data.token}`;
        localStorage.setItem("role", result.data.role);
        return result;
      })
      .catch(() => {
        window.alert("No user found!");
        throw new Error("No user found!");
      });
  }

  getBuilding(search = '') {
    return axios
      .get(`${endpoint}/building?search=${search}`)
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((error) => this.handleError(error));
  }

  getBuildingUsers(id) {
    return axios
      .get(endpoint + "/building/users/" + id, {
        headers: {
          "content-type": "application/json", //the token is a variable which holds the token
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => this.handleError(error));
  }

  addBuildingUser(data) {
    const { name, workerno, building_id, profile } = data;
    return axios
      .post(endpoint + "/building/add-user", {
        name,
        workerno,
        building_id,
        profile,
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        this.handleError(error);
        if (error.response.status === 413) {
          return error.response;
        }
      });
  }

  deleteBuildingUser(userId) {
    return axios
      .post(endpoint + "/building/delete-user", {
        userId,
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        this.handleError(error);
        if (error.response.status === 413) {
          return error.response;
        }
      });
  }

  resendBuildingUser(userId) {
    return axios
      .post(endpoint + "/building/resend-user", {
        userId,
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        this.handleError(error);
        if (error.response.status === 413) {
          return error.response;
        }
      });
  }

  getTenant(id) {
    return axios
      .get(endpoint + "/tenant/" + id)
      .then((res) => {
        return res.data;
      })
      .catch((error) => this.handleError(error));
  }

  getTech(id) {
    return axios
      .get(endpoint + "/tech/" + id)
      .then((res) => {
        return res.data;
      })
      .catch((error) => this.handleError(error));
  }

  getUserNames(id) {
    return axios
      .get(endpoint + "/building-user-name/" + id)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => this.handleError(err));
  }

  getAttendance(month, year, ix) {
    return axios
      .get(`${endpoint}/attendance?month=${month}&year=${year}&id=${ix}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => this.handleError(err));
  }

  // add appartment functionality api call
  addAppartment(formData) {
    return axios
      .post(endpoint + "/addappartment", formData)
      .then((res) => {
        return res.data;
      })
      .catch((err) => this.handleError(err));
  }

  editAppartment(formData, id) {
    return axios
      .put(endpoint + "/appartment/edit/" + id, formData)
      .then((res) => {
        return res.data;
      })
      .catch((err) => this.handleError(err));
  }

  getAppartment(id) {
    return axios
      .get(endpoint + "/appartmentlist/" + id)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => this.handleError(err));
  }

  //  tenant functionality api call
  addTenant(formData) {
    return axios
      .post(endpoint + "/addtenant", formData)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
  }

  getTenant(id) {
    return axios
      .get(endpoint + "/tenant/" + id)
      .then((res) => {
        return res.data;
      })
      .catch((error) => this.handleError(error));
  }
  getApartmentTenant(buildingId, appartmentId) {
    return axios
      .get(endpoint + "/tenantlist/" + buildingId + "/" + appartmentId)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => this.handleError(err));
  }

  editTenant(formData, id) {
    return axios
      .put(endpoint + "/tenant/edit/" + id, formData)
      .then((res) => {
        return res.data;
      })
      .catch((err) => this.handleError(err));
  }

  addProximity(formData) {
    return axios
      .post(endpoint + "/addproximity", formData)
      .then((res) => {
        return res.data;
      })
      .catch((err) => this.handleError(err));
  }

  getProximity(id) {
    return axios
      .get(endpoint + "/proximitylist/" + id)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => this.handleError(err));
  }

  editProximity(formData, id) {
    return axios
      .put(endpoint + "/proximity/edit/" + id, formData)
      .then((res) => {
        return res.data;
      })
      .catch((err) => this.handleError(err));
  }

  handleError(error) {
    if (error.response.status === 401 || error.response.status === 403) {
      window.location.href = "/";
    }
  }

  addNfc(formData) {
    return axios
      .post(endpoint + "/nfc", formData)
      .then((res) => {
        return res.data;
      })
      .catch((err) => this.handleError(err));
  }

  getNfc(id) {
    return axios
      .get(endpoint + "/nfc/list/" + id)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => this.handleError(err));
  }

  editNfc(formData, id) {
    return axios
      .put(endpoint + "/nfc/edit/" + id, formData)
      .then((res) => {
        return res.data;
      })
      .catch((err) => this.handleError(err));
  }

  getPanels(buildingId) {
    return axios
      .get(`${endpoint}/panels/${buildingId}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  resetPanel(panelId) {
    return axios
      .post(`${endpoint}/panels/reset`, { panelId })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

}

export default new Services();
