import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Grid,
  Paper,
  CircularProgress,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Services from "../../services";
import toast from "react-hot-toast";
import Dialog from "../../components/dialog/Dialog"
import TenantListData from "./TenantList";
import AddTenantForm from "./AddTenantForm";


function Tenant() {
  const navigate = useNavigate();
  const ids = useParams();
  const [addTenantOpen, setAddTenantOpen] = useState(false);
  const [isTenantAdded,setIsTenantAdded] = useState()
  const [editData, setEditData] = useState(null)
 
  const [tenant, setTenant] = useState([]);
  const [loading, setLoading] = useState(false)

  const buildingId = ids["*"].split("/")[1];
  const appartmentId = ids?.id
  


  const columns = useMemo(
    () => [
      {
        Header: "BuildingId",
        accessor: "buildingid",
      },
      {
        Header: "Apartment Id",
        accessor: "appartment",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "LastName",
        accessor: "lastname",
      },
      {
        Header: "Cellular",
        accessor: "cellular",
      },
      {
        Header: "Entrance",
        accessor: "entrance",
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
      
    ],
    []
  );

  useEffect(() => {
    
    Services.getApartmentTenant(buildingId, appartmentId).then((res) => {
      setLoading(true)
      if (res) {
        setTenant(res);
        setLoading(false)
      } else {
        toast.error("No apartments found for the given ID");
        setLoading(false)
      }
    });
  }, [isTenantAdded, editData]);

  if (loading) return <CircularProgress />;
  return (
    <>
      <Grid className="add-user-button-cont attendance-page">
        <Button
          className="face-detect-back-button"
          onClick={() => navigate(`/dashboard/building/${buildingId}/appartments`)}
          variant="contained"
        >
          Back
        </Button>
        <Button onClick={() => setAddTenantOpen(true)}  variant="contained">
          Add Tenant
        </Button>
      </Grid>
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
         <TenantListData
         columns={columns}
          data={tenant || []}
          setEditData={setEditData}
           
          />

      </Paper>
      <Dialog
        header={"Add Tenant"}
        open={addTenantOpen === false ? false : true}
        setOpen={setAddTenantOpen}
        type="addTenant"
      >
        <AddTenantForm
          BuildingId={buildingId}
          appartmentId={appartmentId}
          setIsTenantAdded={setIsTenantAdded}
          setAddTenantOpen={setAddTenantOpen}
          setEditData={setEditData}
          setLoading={setLoading}
          loading={loading}
        />
      </Dialog>
    </>
  );
}

export default Tenant;
