import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  CircularProgress
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import services from '../../services';
import { toast } from 'react-hot-toast';

export default function PanelDialog({ open, onClose, buildingId }) {
  const [panels, setPanels] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open && buildingId) {
      fetchPanels();
    }
  }, [open, buildingId]);

  const fetchPanels = async () => {
    setLoading(true);
    try {
      const response = await services.getPanels(buildingId);
      setPanels(response);
    } catch (error) {
      toast.error('Failed to fetch panels');
    } finally {
      setLoading(false);
    }
  };

  const handleReset = async (panelId) => {
    try {
      await services.resetPanel(panelId);
      toast.success('Reset command sent successfully');
    } catch (error) {
      toast.error('Failed to reset panel');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Building Panels</DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : (
          <List>
            {panels.map((panel) => (
              <ListItem key={panel.id}>
                <ListItemText primary={panel.name} />
                <ListItemSecondaryAction>
                  <IconButton 
                    edge="end" 
                    onClick={() => handleReset(panel.ix)}
                    color="primary"
                  >
                    <RefreshIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )}
      </DialogContent>
    </Dialog>
  );
}