import React, { useState } from "react";
import toast from "react-hot-toast";
import { Button, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { useParams } from "react-router-dom";
import services from "../../services";

export default function NfcForm({
  setAddNfcOpen,
  setIsNfcAdded,
  setLoading,
  loading,
  filteredData,
  setOpen,
}) {
  const { id } = useParams();
  const [name, setName] = useState(
    filteredData?.name ? filteredData?.name : ""
  );

  const [code, setCode] = useState(
    filteredData?.code ? filteredData?.code : ""
  );
  
  const [panelSerial, setPanelSerial] = useState(
    filteredData?.panel_serial ? filteredData?.panel_serial : 0
  );
  
  const handleSubmit = (e) => {
    e.preventDefault();

    const regex = /^[0-9]+$/;
    if (!name || !code) {
      toast.error("All fields are required");
      return;
    } else if (!regex.test(panelSerial)) {
      toast.error("Panel Serial should be only number");
      return;
    }else if (panelSerial < -128 || panelSerial > 127) {
      toast.error("Panel Serial value out of range for TINYINT");
      return;
    }

    const formData = {
      name: name,
      panel_serial: panelSerial,
      code: code,
      building_ix: id,
    };

    if (filteredData?.id) {
      services
        .editNfc(formData, filteredData?.id)
        .then((res) => {
          toast.success(res.message);
          setOpen(null);
          if (res) {
            window.location.reload();
          }
        })

        .catch((err) => console.log(err.response));
    } else {
      setLoading(true);
      services
        .addNfc(formData)
        .then((res) => {
          toast.success(res.message);
          setIsNfcAdded(res);
          setName("");
          setLoading(false);
          setAddNfcOpen(false);
        })
        .catch((err) => {
          console.log(err.response);
          setLoading(false);
        });
    }
  };

  return (
    <form className="add-user-form" onSubmit={handleSubmit}>
      <div>
        <InputLabel for="profile">Name</InputLabel>
        <TextField
          fullWidth
          name="name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>

      <div>
        <InputLabel for="user">Code</InputLabel>
        <TextField
          fullWidth
          name="code"
          type="text"
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
      </div>

      <div>
        {/* Radio button group */}

        <InputLabel>Panel Serial</InputLabel>
        
        <TextField
          fullWidth
          name="panelSerial"
          type="text"
          value={(panelSerial)??0}
          onChange={(e) => setPanelSerial(e.target.value)}
        />
      </div>
      <Button
        disabled={loading}
        color="primary"
        variant="contained"
        fullWidth
        type="submit"
      >
        Submit
      </Button>
    </form>
  );
}
