import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useNavigate } from "react-router-dom";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

export const MainListItems = () => {
  const navigate = useNavigate();

  const handleLogout = () => {

    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    // Remove the role from localStorage
    localStorage.removeItem("role");

    // Optional: Redirect the user to the login page or home page
    window.location.href = "/"; // Adjust the path as needed
  };


  return (
    <div style={{
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
      height: "100%"
    }}>
      <div>
        <ListItemButton onClick={() => navigate("/dashboard/building")}>
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary="Building" />
        </ListItemButton>
      </div>
      <div style={{

      }}>
        <ListItemButton onClick={handleLogout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
      </div>
    </div >
  );
};
